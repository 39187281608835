import { ERROR_RESPONSE } from "../../constants/error-response.c";
import { useTranslation } from "react-i18next";
import Error from "../../components/error/Error.component";
import HppWrapper from "../../components/main-wrapper/HppWrapper";
import { useGoogleAnalyticsPageView } from "../../components/hooks/google-analytics";
import { base64ToString } from "../../utils/helper";
import useGetParameter from "../../components/hooks/useGetParameter";
import { NavLink } from "react-router-dom";
import { URL_PARAM } from "../../constants/url-param.c";
import { cancelParamSplitterStr } from "../../constants/common.c";
import "./style.css";

const ErrorPage = ({ error = 404 }: any) => {
  useGoogleAnalyticsPageView(); // For google analytics
  let ErrorType = ERROR_RESPONSE[error];
  let parameterData = useGetParameter(URL_PARAM.data);
  let data: any = parameterData ? base64ToString(parameterData).split(cancelParamSplitterStr) : [];
  let cancel_url = data.shift();
  let isLanguageSwitcherDisabled = data.shift() || false;
  const { t } = useTranslation();

  return (
    <div className="h-100 o-hidden">
    <HppWrapper apiData={{ "config": { "isLanguageSwitcherDisabled": isLanguageSwitcherDisabled === 'true' ? true : false } }} >
      <div className="w-40rem">
        <div className="background-wrapper flex-column">
          <Error
            status={t(ErrorType.code)}
            title={t(ErrorType.message)}
            heading={t(ErrorType.heading)}
            // if we are on cancel page and we have cancel url then show the back to merchant button and reverse counter
            description={
              cancel_url ?
                <>
                  <NavLink className={"back-home"}
                    to={cancel_url}>
                    {t("Payment_Failed.Prev_Link")}{" "}
                  </NavLink>
                </>
                : t(ErrorType.description)}
          />

        </div>
      </div>
    </HppWrapper>
    </div>
  );
};

export default ErrorPage;