import React, { useEffect } from 'react'
import "./style.css"
import { useLocation, useNavigate } from 'react-router-dom';

const Layout = ({children, className}:any) => {



  return (
    <div className={className}>{children}</div>
  )
}

export default Layout