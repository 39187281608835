import React, { useEffect, useState } from 'react'
import { applePayController } from '../apple-pay-button/components/apple-pay';
import { apmKeyApplePayKey, apmKeys } from '../../constants/common.c';

const DasCustomGpayButton = ({ slug,apiData, setAmountPopup }: any) => {
  let [isFullWidthButton,setIsFullWidthButton] = useState(true);
  useEffect(()=>{
    const componentKey = "hasGooglePay";
    let applicableKey = apmKeys.filter(i=> i!=componentKey && apiData[i] );
    if (applicableKey.length > 0 ) {
        if (applicableKey.length == 1 && applicableKey.includes(apmKeyApplePayKey) && applePayController.isAppleSessionAvailable()) {
          setIsFullWidthButton(false)
        }else{
          setIsFullWidthButton(true)
        }
    };
  },[apiData])

  return (
    <>
      <button
        className={
          isFullWidthButton ? "google-pay-button full-width-button" : "google-pay-button"  
        }
        onClick={() => {
      localStorage.setItem("slug", slug);

          setAmountPopup((prev: any) => {
            return {
              ...prev,
              open: true,
              logo: "/google-pay.png",
              type: "gpay",
            };
          });
        }}
      >
        <img
          src={"/google-pay.png"}
          alt="Google Pay Logo"
          className="google-pay-icons"
        />
      </button>
    </>
  );
};

export default DasCustomGpayButton