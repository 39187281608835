/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FAILED from "../../components/assets/payment/payment-failed.png";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReverseCounter from "../../components/reverse-counter/ReverseCounter";
import { REDIRECT_COUNT, REDIRECT_COUNT_FOR_IBEC } from "../../constants/timeout-intervals";
import { LANGUAGES } from "../../constants/languages.c";
import { BASE_API_URL } from "../../constants/api-path.c";
import Api from "../../utils/Api";
import Loader from "../../components/loader/Loader.component";
import HppWrapper from "../../components/main-wrapper/HppWrapper";
import { useGoogleAnalyticsPageView, useGoogleAnalyticsClickEvent, categoryGA } from "../../components/hooks/google-analytics";
import useIbec from "../../components/hooks/useIBEC";

function clearStorageAfterDuration(duration:any) {
  setTimeout(() => {
   
    localStorage.removeItem('transactionContext');
  }, duration*1000);
}

export default function FailedPage() {
  useGoogleAnalyticsPageView(); // For google analytics
  const pushClickEventAnalytics = useGoogleAnalyticsClickEvent();  // For google analytics

  const { failed }: any = useParams();
  const [transactionDetail, setTransactionDetail] = useState<any>("");
  const [declineURL, setDeclineURL] = useState<any>("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  let localLang = localStorage.getItem("lang");

  // Ms1NbEvq;
  const fetchData = () => {
    pushClickEventAnalytics(categoryGA.paymentFailed, ` ${localStorage.getItem("slug")}`); // For google analytics
    Api()
      .post(`${BASE_API_URL}/payment/details`, {
        URLKey: localStorage.getItem("slug"),
        transactionId: failed
      })
      .then((res) => {
        localStorage.setItem('transactionContext' , JSON.stringify(res));
        clearStorageAfterDuration(REDIRECT_COUNT_FOR_IBEC.FAIL)
        setTransactionDetail(res)
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let originalURL = window.location.pathname;
    let modifiedURL;
    if (localLang === LANGUAGES.EN) {
      modifiedURL = originalURL.replace("/jp/", "/");
      navigate(modifiedURL);
    }
    const interval = setInterval(() => { }, 1);
    return () => {
      clearInterval(interval);
      window.location.reload();
    };
  }, [localLang, navigate]);

  const { isIbec } = useIbec(transactionDetail);

  const COUNTER = isIbec ? REDIRECT_COUNT_FOR_IBEC.FAIL : REDIRECT_COUNT.FAIL;

  useEffect(() => {
    const decline_url = transactionDetail?.return_url?.decline_url?.includes("?")
      ? `${transactionDetail?.return_url?.decline_url}&transaction_reference=${transactionDetail?.Ref}`
      : `${transactionDetail?.return_url?.decline_url}?transaction_reference=${transactionDetail?.Ref}`;
    setDeclineURL(decline_url);
    //console.log("decline_url" , decline_url , "declineURL" ,declineURL)
    setTimeout(() => {
      if (transactionDetail?.return_url?.decline_url) {
        window.location.href = decline_url;//transactionDetail?.return_url?.decline_url;
      }
    }, COUNTER * 1000);
  }, [transactionDetail?.return_url?.decline_url]);

  if (!transactionDetail) {
    return <Loader />;
  } else
    return (
      <HppWrapper apiData={transactionDetail}>
        <div className="w-40rem">
          <div className="background-wrapper">
            <div className="payment-container">
              <React.Fragment>
                <div className="combo-cont">
                  <div className="payment-box">
                    <div className="payment-img-container">
                      <img
                        src={FAILED}
                        className="payment-img-icon"
                        alt="FAILED"
                      />
                      <h4 className="payment-status payment-failed-red">
                        {t("Payment_Failed.status")}
                      </h4>
                    </div>
                    {/* <p className="transaction-id-no">
                      {t("Payment_Failed.message")}{" "}
                      <strong>
                        {transactionDetail?.paymentDetail?.Amount?.currencyCode}{" "}
                        {transactionDetail?.paymentDetail?.Amount?.value}
                      </strong>{" "}
                      to <strong>{transactionDetail?.Merchant}</strong>
                    </p> */}
                    <div className="tcenter">
                      {!transactionDetail?.return_url?.decline_url ? (
                        ""
                      ) : (
                        <NavLink className="back-home" to={declineURL}>
                          {t("Payment_Failed.Prev_Link")}{" "}
                        </NavLink>
                      )}
                    </div>
                  </div>
                  {!transactionDetail?.return_url?.decline_url ? (
                    ""
                  ) : (
                    <ReverseCounter
                      count={COUNTER}
                      prefixText={t("Payment_Successful.Redirect_Prefix_Text")}
                      suffixText={t("Payment_Successful.Redirect_Suffix_Text")}
                    />
                  )}
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </HppWrapper>
    );
}
