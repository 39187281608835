import { useState, useEffect } from "react";
import PayWithApplePay from "./components/PayWithApplePay";
import ApplePayButtonStatusConstant from "./components/constants/apple-pay-button-status.c";
import { applePayController } from "./components/apple-pay";
import { useTranslation } from "react-i18next";
import { apmKeyApplePayKey, apmKeys } from "../../constants/common.c";
const ApplePayButton = ({
  apiData,
  setAmountPopup,
  handleApplePayClick,
}: any) => {

  let [isFullWidthButton,setIsFullWidthButton] = useState(true);
  useEffect(()=>{
    const componentKey = apmKeyApplePayKey;
    let applicableKey = apmKeys.filter(i=> i!=componentKey && apiData[i] );
    if (applicableKey.length > 0 ) {
        if (applicableKey.length == 1 && applePayController.isAppleSessionAvailable()) {
          setIsFullWidthButton(false)
        }else{
          setIsFullWidthButton(true)
        }
    };
  },[apiData])

  const [applePayButtonStatus, setapplePayButtonStatus] = useState(
    ApplePayButtonStatusConstant.UNKNOWN
  );
  const { t } = useTranslation();
  useEffect(() => {
    applePayController.isAppleSessionAvailable()
      ? setapplePayButtonStatus(ApplePayButtonStatusConstant.AVAILABLE)
      : setapplePayButtonStatus(ApplePayButtonStatusConstant.NOT_AVAILABLE);
  }, []);

  const handleCheckAmount = () => {
    if (!apiData.amount) {
      setAmountPopup((prev: any) => {
        return { ...prev, open: true, logo: "/apple-pay.png" };
      });
    } else {
      handleApplePayClick();
    }
  };

  let getButton = () => {
    switch (applePayButtonStatus) {
      case ApplePayButtonStatusConstant.UNKNOWN:
        return (
          <div className="text-muted text-center"> Checking Apple Pay... </div>
        );
      case ApplePayButtonStatusConstant.AVAILABLE:
        return (
          <>
            <div className={
                isFullWidthButton ? "apple-pay-button-div full-width-button" : "apple-pay-button-div"
              } id="apple-pay-row">
          <div
            className="apple-pay-button apple-pay-button-black"
            id="apple-pay"
            onClick={handleCheckAmount}
          >
            <PayWithApplePay />
          </div>
              </div>
          </>
        );
      case ApplePayButtonStatusConstant.NOT_AVAILABLE:
        return (
          <>
            {!apiData?.buttons?.enableCardPayment && <div className={"apple-pay-button-div full-width-button"} id="apple-pay-row">
          <div
            className="apple-pay-button apple-pay-button-black with-white-inactive-text"
          >
            {t("Apple Pay inactive on your device.")}
          </div>
              </div>}
          </>
        );
  
      default:
        return <div> Invalid status!!! </div>;
    }
  };
  return (
    <>
      {getButton()}
    </>
  );
};

export default ApplePayButton;
