import axios from "axios";
import {
  BAD_REQUEST_PAGE,
  SERVER_ERROR_PAGE,
} from "../../constants/path.c";
// import { Buffer } from "buffer";
import { isJapanese } from "../hooks/isJapanese";
import { BASE_API_URL } from "../../constants/api-path.c";
import "./style.css";
import { applePayController } from "../apple-pay-button/components/apple-pay";
import { useEffect, useState } from "react";
import logo from "../assets/paypay.svg";
import { useNavigate } from "react-router-dom";
import { apmKeyApplePayKey, apmKeys } from "../../constants/common.c";

export const DasPayPayButton = ({
  apiData,
  setApiData,
  setDasLoader,
  slug,
  setAmountPopup
}: any) => {
  let [isFullWidthButton, setIsFullWidthButton] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    let componentKey = "hasPayPay";
    let applicableKey = apmKeys.filter(i => i != componentKey && apiData[i]);
    if (applicableKey.length > 0) {
      if (applicableKey.length == 1 && applicableKey.includes(apmKeyApplePayKey) && applePayController.isAppleSessionAvailable()) {
        setIsFullWidthButton(false)
      } else {
        setIsFullWidthButton(true)
      }
    };
  }, [apiData])
  localStorage.setItem("slug", slug);

  const handleClick = async () => {
    if (!apiData.amount) {
      setAmountPopup((prev: any) => {
        return {
          ...prev,
          open: true,
          logo: logo,
          type: "paypay",
        };
      });
    }else{
      setDasLoader(true);
      return new Promise(function (resolve, reject) {
        const BACKEND_URL_PAY = `${BASE_API_URL}/wallet/capture/payment`;
        const payload = {
          URLKey: slug,
          amount: Number(apiData.amount),
          payment_method: {
            type: "paypay",
          },
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        // Make an HTTP POST request to your server to process the payment
        axios
          .post(BACKEND_URL_PAY, payload, {
            headers: {
              "x-secret-key": process.env.REACT_APP_PUBLIC_SECRET_KEY,
              "x-api-key": process.env.REACT_APP_PUBLIC_API_KEY,
            },
          })
          .then((response: any) => response.data)
          .then((result: any) => {
            if (!!result?.redirecturl || !!result.additional_parameters) {
              if (result.additional_parameters && result.additional_parameters.type === "FULL-PAGE-HTML-CONTENT") {
                let wnd:any = window.open( "", "_self");
                wnd.document.write(result.additional_parameters.html);
              }else{
                window.open(result?.redirecturl,"_self")
              }
            }
              // Handle the payment error case
              reject({ message: result?.message });
          })
          .catch((error) => {
            const result = error.response.data;
            const japanese = isJapanese();
            let redirectURL;
            // const objJsonStr = JSON.stringify(result);
            // const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
            if (result.hasOwnProperty("success") && result.success === false) {
              redirectURL = `/${SERVER_ERROR_PAGE}/${result?.Ref}`;
            } else {
              redirectURL = `/${BAD_REQUEST_PAGE}`;
            }
            if (japanese) {
              navigate(`/jp${redirectURL}`);
            } else {
              navigate(redirectURL);
            }
            // reject({ message: "Payment processing failed" });
          });
      });
    }
  }
  return (
    <div className={isFullWidthButton ? "pay-pay-button full-width-button" : "pay-pay-button"} onClick={handleClick}>
      <img className="pay-pay-logo" src={logo} alt="Pay pay logo" />
    </div>
  );
};
