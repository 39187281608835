import axios from "axios";
// import { Buffer } from "buffer";
import { SUCCESS_PAGE, SERVER_ERROR_PAGE, BAD_REQUEST_PAGE } from "../../../constants/path.c";
import { BASE_API_URL} from "../../../constants/api-path.c";
import { URL_LANGUAGE } from "../../../constants/inital-lang.c"
import { LANGUAGES } from "../../../constants/languages.c"
  /**
   * This the controller for Apple Pay where all the business logic can be handled.
   *
   * @param {uiController} applePayUiController The UI controller
   *
   * @return {object} Contains a simple init function
   *
   */
  export const applePayController = (function () {
    let navigate = '';
    var BACKEND_URL_VALIDATE_SESSION = `https://c956-202-63-156-125.ngrok-free.app/api/v1/hpp/applepay/sessions`
    var BACKEND_URL_PAY = `${BASE_API_URL}/wallet/capture/payment`
    let SLUG = ''
    const ApplePaySession = window.ApplePaySession
    // High level configuration options.
    var config = {
      payments: {
        acceptedCardSchemes: ['masterCard',  'visa'] //['amex', 'masterCard', 'maestro', 'visa', 'mada']
      },
      /*shipping: {
        JP_region: [
          {
            label: 'Free Shipping',
            amount: '0.00',
            detail: 'Arrives in 3-5 days',
            identifier: 'freeShipping'
          },
          {
            label: 'Express Shipping',
            amount: '5.00',
            detail: 'Arrives in 1-2 days',
            identifier: 'expressShipping'
          }
        ],
        WORLDWIDE_region: [
          {
            label: 'Worldwide Standard Shipping',
            amount: '10',
            detail: 'Arrives in 5-8 days',
            identifier: 'worldwideShipping'
          }
        ]
      }*/
    }
  
    /**
     * Checks if Apple Pay is possible in the current environment.
     *
     * @return {boolean} Boolean to check if Apple Pay is possible
     */
    const _applePayAvailable = function () {
      return window.ApplePaySession && ApplePaySession.canMakePayments()
    }
    const _isJapanese = function(){
      if (URL_LANGUAGE===LANGUAGES.JP){
          return true
      } else{
          return false
      }
    }

    const _setNavigate = function(redirect){
      navigate = redirect
    }

    const _setBackendValidateURL = function (url) {
          BACKEND_URL_VALIDATE_SESSION = url
    }
  

    const _setSlug = function (slug) {
      localStorage.setItem("slug",slug);
        SLUG = slug;
    }
    /**
     * Starts the Apple Pay session using a configuration
     */
    const _startApplePaySession = function (config) {
      var applePaySession = new ApplePaySession(6, config)
      _handleApplePayEvents(applePaySession)
      applePaySession.begin()
    }
  
    /**
     * Sets a onClick listen on the Apple Pay button. When clicked it will
     * begin the Apple Pay session with your configuration
     */
    const _setButtonClickListener = function () {
      console.log("config" , JSON.stringify({
        currencyCode: config?.shop?.shop_localisation?.currencyCode,
        countryCode: config?.shop?.shop_localisation?.countryCode,
        merchantCapabilities: [
          'supports3DS',
          'supportsEMV',
          'supportsCredit',
          'supportsDebit'
        ],
        supportedNetworks: config.payments.acceptedCardSchemes,
        //shippingType: 'shipping',
        requiredBillingContactFields: [
          'postalAddress',
          'name',
          'phone',
          'email'
        ],
        /*requiredShippingContactFields: [
          'postalAddress',
          'name',
          'phone',
          'email'
        ],*/
        total: {
          label: config.shop.shop_name,
          amount: config.shop.product_price,
          type: 'final'
        }
      }));
      //document
        //.getElementById(uiController.DOMStrings.appleButton)
       // .addEventListener('click', function () {
          _startApplePaySession({
            currencyCode: config?.shop?.shop_localisation?.currencyCode,
            countryCode: config?.shop?.shop_localisation?.countryCode,
            merchantCapabilities: [
              'supports3DS'
            ],
            supportedNetworks: config.payments.acceptedCardSchemes,
            //shippingType: 'shipping',
            requiredBillingContactFields: [
              'postalAddress',
              'name',
              'phone',
              'email'
            ],
            /*requiredShippingContactFields: [
              'postalAddress',
              'name',
              'phone',
              'email'
            ],*/
            total: {
              label: config.shop.shop_name,
              amount: config.shop.product_price,
              type: 'final'
            }
          })
        //})
    }
  
    /**
     * This method call your backend server with the Apple Pay validation URL.
     * On the backend, an POST request will be done this URL with the Apple Pay certificates
     * and the outcome will be returned
     *
     * @param {string} appleUrl The Apple Pay validation URL generated by Apple
     * @param {function} callback Callback function used to return the server call outcome
     *
     * @return {object} The payment request configuration
     *
     */
    const _validateApplePaySession = function (appleUrl, callback) {
      // I'm using AXIOS to do a POST request to the backend but any HTTP client can be used
      axios
        .post(
          BACKEND_URL_VALIDATE_SESSION,
          {
            
            displayName:"My Merchant"
          },
          {
            headers: {
              "x-secret-key": process.env.REACT_APP_PUBLIC_SECRET_KEY,
              "x-api-key": process.env.REACT_APP_PUBLIC_API_KEY
            },
            withCredentials: true,
          }
        )
        .then(function (response) {
          callback(response.data)
        })
        .catch((e)=>console.log("e" , e))
    }
  
    /**
     * This method returns the available payment methods for a certain region. You can add
     * your business logic here to determine the shipping methods you need.
     *
     * @param {string} 2 Letter ISO of the region
     *
     * @return {Array} An array of shipping methods
     *
     */
    const _getAvailableShippingMethods = function (region) {
      // return the shipping methods available based on region
      if (region === 'GB') {
        return { methods: config.shipping.GB_region }
      } else {
        return { methods: config.shipping.WORLDWIDE_region }
      }
    }
  
    /**
     * This is the main method of the script, since here we handle all the Apple Pay
     * events. Here you are able to populate your shipping methods, react to  shipping methods
     * changes, and many other interaction that the user has with the Apple Pay pup-up.
     *
     * @param {object} Apple Pay Session (the one generate on the button click)
     *
     */
    const _handleApplePayEvents = function (appleSession) {
      // This is the first event that Apple triggers. Here you need to validate the
      // Apple Pay Session from your Back-End
      appleSession.onvalidatemerchant = function (event) {
        
        _validateApplePaySession(event.validationURL, function (merchantSession) {
          appleSession.completeMerchantValidation(merchantSession)
        })
      }
  
      // This method is triggered before populating the shipping methods. This is the
      // perfect place inject your shipping methods
      appleSession.onshippingcontactselected = function (event) {
        // populate with the availbale shipping methods for the region
        var shipping = _getAvailableShippingMethods(
          config.shop.shop_localisation.countryCode
        )
        // Update total and line items based on the shipping methods
        var newTotal = {
          type: 'final',
          label: config.shop.shop_name,
          amount: _calculateTotal(
            config.shop.product_price,
            shipping.methods[0].amount
          )
        }
        var newLineItems = [
          {
            type: 'final',
            label: 'Subtotal',
            amount: config.shop.product_price
          },
          {
            type: 'final',
            label: shipping.methods[0].label,
            amount: shipping.methods[0].amount
          }
        ]
        appleSession.completeShippingContactSelection(
          ApplePaySession.STATUS_SUCCESS,
          shipping.methods,
          newTotal,
          newLineItems
        )
      }
  
      // This method is triggered when a user select one of the shipping options.
      // Here you generally want to keep track of the transaction amount
      appleSession.onshippingmethodselected = function (event) {
        var newTotal = {
          type: 'final',
          label: config.shop.shop_name,
          amount: _calculateTotal(
            config.shop.product_price,
            event.shippingMethod.amount
          )
        }
        var newLineItems = [
          {
            type: 'final',
            label: 'Subtotal',
            amount: config.shop.product_price
          },
          {
            type: 'final',
            label: event.shippingMethod.label,
            amount: event.shippingMethod.amount
          }
        ]
        appleSession.completeShippingMethodSelection(
          ApplePaySession.STATUS_SUCCESS,
          newTotal,
          newLineItems
        )
      }
  
      // This method is the most important method. It gets triggered after teh user has
      // confirmed the transaction with the Touch ID or Face ID. Besides getting all the
      // details about the customer you also get the Apple Pay payload needed to perform
      // a payment.
      appleSession.onpaymentauthorized = function (event) {
        _performTransaction(event.payment, function (outcome) {
          let redirectURL;
          if (outcome.success) {
            appleSession.completePayment(ApplePaySession.STATUS_SUCCESS)
            // const objJsonStr = JSON.stringify(outcome);
            // const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
            redirectURL = `/${SUCCESS_PAGE}/${outcome?.Ref}`;
            redirectURL = `/${SUCCESS_PAGE}/${outcome?.Ref}`;
            
            
          } else {
            appleSession.completePayment(ApplePaySession.STATUS_FAILURE);
            // const objJsonStr = JSON.stringify(outcome);
            // const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
            if (outcome.hasOwnProperty("success") && outcome.success === false) {
              redirectURL = `/${SERVER_ERROR_PAGE}/${outcome?.Ref}`;
            } else {
              redirectURL = `/${BAD_REQUEST_PAGE}`;
            }
            console.log(outcome)
          }
          if (_isJapanese()) {
            navigate(`/jp${redirectURL}`);
          } else {
            navigate(redirectURL);
          }
        })
      }
  
      appleSession.oncancel = function (event) {
        console.log(event)
      }
    }
  
    var _performTransaction = function (details, callback) {
      // I'm using AXIOS to do a POST request to the backend but any HTTP client can be used

      console.log("details" , {
        "URLKey": SLUG,
        "amount": Number(config.shop.product_price),
        "payment_method":{
            "network":details?.token?.paymentMethod?.network.toUpperCase(),
            "type":"applepay",
            "applePayToken":details.token.paymentData
        },
        "tz": Intl.DateTimeFormat().resolvedOptions().timeZone
    })
      axios
        .post(
          BACKEND_URL_PAY,
          /*{
            //details,
            "amount":config.shop.product_price,
            "currency":config.shop.shop_localisation.currencyCode,
              "payment_method":{
                  "type":"applepay",
                  "applePayToken":JSON.stringify(details.token.paymentData)
              },
            "merchant_txn_ref" :"qwe",
            "customer_ip" : "10.10.0.0",
            "merchant_id" : "POJP000068",
            "return_url":{
          
              "webhook_url": "https://sme.dasgateway.com/v1/mc",
              "success_url": "https://www.google.com/",
              "decline_url": "https://www.bing.com/"
            },
            "billing_address":{
          
              "country": "JP",
              "email": "test.tes@email.com",
              "address1": "47A123",
              "phone_number": "9999999999",
              "city": "ACambridge",
              "state": "Cambridgeshire",
              "postal_code": "CB94BQ"
            
            },
            "shipping_address":{
          
              "country": "IN",
              "email": "shiptest@test.com",
              "address1": "adfadfdsf",
              "phone_number": "111111",
              "city": "ACambridge",
              "state": "Cambridgeshire",
              "postal_code": "3534534"
            },
            "time_zone": "Asia/Kuala_Lumpur",
            "cardinal_session_id":""
          }*/
          {
            "URLKey": SLUG,
            "amount": Number(config.shop.product_price),
            "payment_method":{
                "network":details?.token?.paymentMethod?.network.toUpperCase(),
                "type":"applepay",
                "applePayToken":JSON.stringify(details.token.paymentData)
            },
            "tz": Intl.DateTimeFormat().resolvedOptions().timeZone
        },
          {
            headers: {
              "x-secret-key": process.env.REACT_APP_PUBLIC_SECRET_KEY,
              "x-api-key": process.env.REACT_APP_PUBLIC_API_KEY
            }
          }
        )
        .then(function (response) {
          callback(response.data)
        })
        .catch(function(error){
          callback(error.response.data)
        })
    }
  
    const _calculateTotal = function (subtotal, shipping) {
      return (parseFloat(subtotal) + parseFloat(shipping)).toFixed(2)
    }
  
    return {
      setNavigate: (redirect)=>{
        _setNavigate(redirect)
      },
      setSlug: (slug)=>{
          _setSlug(slug)
      },
      setBackendValidateURL:(url)=>{
            _setBackendValidateURL(url)
      },
      setConfig:(data)=>{
        config.shop = {
          product_price: data.amount,
          shop_name: data.company,
          shop_localisation: {
            currencyCode: data.currency,
            countryCode: data.entity
          }
        }
      },
      isAppleSessionAvailable :() =>{
        //return true
        return _applePayAvailable();
      },
      applyButtonClickListener:()=>{
        _setButtonClickListener()
      },
      init: function () {
        if (_applePayAvailable()) {
         // uiController.displayApplePayButton()
        } else {
         // uiController.displayErrorMessage()
        }
        _setButtonClickListener()
      }
    }
  })()