import { useState } from "react";
import { DasGooglePayButton } from "./../google-pay-button/DasGooglePayButton";
import { useTranslation } from "react-i18next";
import { DasPayPayButton } from "../pay-pay-button/DasPayPayButton";

const EmptyAmountPopup = ({
  amountPopup,
  logo,
  slug,
  heading,
  buttonText,
  setAmountPopup,
  apiData,
  setApiData,
  handleApplePayClick,
  setDasLoader,
  navigate
}: any) => {
  const [amount, setAmount] = useState(apiData?.amount);
  const handleChange = (e: any) => {
    setAmount(e.target.value);
    let temp = apiData;
    setApiData({
      ...temp,
      amount: e.target.value,
    });
  };
const {t} = useTranslation();
const cancel = t("merchant.button.cancel");

  return (
    <div className="amount-popup-overlay">
      <div className="amount-popup">
        <img
          src={amountPopup.logo}
          alt="Apple Pay Logo"
          className="pay-icons"
        />
        <button
          className="cancel-btn"
          onClick={() =>
            setAmountPopup((prev: any) => {
              return { ...prev, open: false };
            })
          }
        >
{cancel}
        </button>
        <h1>{t(heading)}</h1>
        <div className="wrap-inputs">
          <input type="number" value={amount} onChange={handleChange} />
          
          {amountPopup.type === "gpay" ? (
            amount > 0 && (
              <DasGooglePayButton
                navigate={navigate}
                setDasLoader={setDasLoader}
                apiData={apiData}
                slug={slug}
              />
            )
          ) : (
            amountPopup.type === "paypay"  ? (
              amount > 0 && (
                <DasPayPayButton
                apiData={apiData}
                setApiData={setApiData}
                setDasLoader={setDasLoader}
                slug={slug}
                setAmountPopup={setAmountPopup}
              />)
            ) 
          :(
            <button
              disabled={amount < 1}
              onClick={handleApplePayClick}
              className="continue-btn"
            >
              {buttonText}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
export default EmptyAmountPopup;
