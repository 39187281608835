import React from 'react'
import { useTranslation } from 'react-i18next';

const DasORSeparator = ({ text = "Or Pay With Card", text2 = "", contentWidth = 60 }: { text?: string, text2?: string, contentWidth?: number }) => {
    const { t } = useTranslation();

    const hrStyle = { width: `${100 - contentWidth}%`, opacity: 0.3 };
    return (
        <div className='pay-seperator' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <hr style={hrStyle} />
            <p style={{ width: `${contentWidth}%`, textAlign: "center", fontSize: "13px" }}>
                {t(text)}
                {
                    text2 && <>
                        <br />
                        {t(text2)}
                    </>
                }
            </p>
            <hr style={hrStyle} />
        </div>
    )
}

export default DasORSeparator;
