import React, { useEffect, useState } from 'react';
import Loader from "../../components/loader/Loader.component";

interface Redirect {
  redirecturl: string;
  additional_parameters: {
    parameters: any;
    preconditions?: any;
  };
}

interface Parameter {
  name: string;
  value: string;
}

interface Props {
  result: Redirect;
}

const RedirectNomupayHandler: React.FC<Props> = ({ result }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const handleRedirect = (result: Redirect) => {
      if (result?.additional_parameters?.preconditions && result?.additional_parameters?.preconditions.length > 0) {
        // Loop through preconditions if necessary
        for (const precondition of result?.additional_parameters?.preconditions) {
          const preconditionIframe = document.createElement('iframe');
          preconditionIframe.style.display = 'none';
          document.body.appendChild(preconditionIframe);

          const preconditionIframeDoc = preconditionIframe.contentDocument || preconditionIframe.contentWindow?.document;
          if (!preconditionIframeDoc) {
            console.error("Iframe contentWindow or contentDocument is null.");
            return;
          }

          const preconditionForm = preconditionIframeDoc.createElement('form');
          preconditionForm.method = precondition.method;
          preconditionForm.action = precondition.url;

          // Add hidden inputs for precondition parameters
          precondition.parameters.forEach((param: Parameter) => {
            const input = preconditionIframeDoc.createElement('input');
            input.type = 'hidden';
            input.name = param.name;
            input.value = param.value;
            preconditionForm.appendChild(input);
          });

          preconditionIframeDoc.body.appendChild(preconditionForm);
          preconditionForm.submit();

          // On iframe load, handle the next redirect step
          preconditionIframe.onload = () => {
            preconditionIframe.parentNode?.removeChild(preconditionIframe);
            handleRedirectAfterPrecondition(result);
          };
        }
      }else if(!result?.additional_parameters?.hasOwnProperty("preconditions")){
        handleRedirectWithoutPreCondition(result)
      } 
      else {
        // If no preconditions, redirect immediately
        return null;
      }
    };

    const handleRedirectAfterPrecondition = (result: Redirect) => {
      const redirectIframe = document.createElement('iframe');
      redirectIframe.style.display = 'block';
      redirectIframe.style.width = '100vw'; // Use viewport width for full coverage
      redirectIframe.style.height = '100vh'; // Use viewport height for full coverage
    
  
      document.body.appendChild(redirectIframe);

      const redirectIframeDoc = redirectIframe.contentDocument || redirectIframe.contentWindow?.document;
      if (!redirectIframeDoc) {
        console.error("Iframe contentWindow or contentDocument is null.");
        return;
      }
      const redirectForm: any = redirectIframeDoc.createElement('form');
      redirectForm.method =  'POST'; 
      redirectForm.action = result.redirecturl;
      //redirectForm.target = "_self"; // Set target
      
      // Add hidden inputs if there are precondition parameters
      if (result?.additional_parameters.preconditions && result?.additional_parameters?.preconditions.length > 0) {
        result.additional_parameters.preconditions[0].parameters.forEach((param: Parameter) => {
          const input = redirectIframeDoc.createElement('input');
          input.type = 'hidden';
          input.name = param.name;
          input.value = param.value;
          redirectForm.appendChild(input);

          document.body.appendChild(redirectForm);
          redirectForm.submit(); // Submit the form
        });
      }
      redirectIframe.onload = () => {
        setIsLoading(false); // Hide the loader once the iframe loads
      };
    };

    const handleRedirectWithoutPreCondition = (result: Redirect)=>{
      const redirectIframe = document.createElement('iframe');
      redirectIframe.style.display = 'block';
      redirectIframe.style.width = '100vw'; // Use viewport width for full coverage
      redirectIframe.style.height = '100vh'; // Use viewport height for full coverage
    
  
      document.body.appendChild(redirectIframe);

      const redirectIframeDoc = redirectIframe.contentDocument || redirectIframe.contentWindow?.document;
      if (!redirectIframeDoc) {
        console.error("Iframe contentWindow or contentDocument is null.");
        return;
      }
      const redirectForm: any = redirectIframeDoc.createElement('form');
      redirectForm.method =  'POST'; 
      redirectForm.action = result.redirecturl;

      if(result?.additional_parameters.parameters && result?.additional_parameters?.parameters.length > 0) {
      result?.additional_parameters?.parameters.forEach((param: Parameter) => {
          let input = redirectIframeDoc.createElement('input');
          input.type = 'hidden';
          input.name = param.name;
          input.value = param.value;
          redirectForm.appendChild(input);

          document.body.appendChild(redirectForm);
          redirectForm.submit();
      });
     }
      redirectIframe.onload = () => {
        setIsLoading(false); // Hide the loader once the iframe loads
      };
  }

    handleRedirect(result);
  }, [result]);

  return (
    <>
      {isLoading && (<Loader/>)}
    </>
  );
};

export default RedirectNomupayHandler;

